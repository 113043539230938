import { GetFestivalCopy } from '../..'
import { getVoucherDetailsFaq, getVoucherDetailsFaqSummary, VoucherDetailsCopy } from '..'

export const getRwCopy: GetFestivalCopy<VoucherDetailsCopy> = gt => ({
  title: gt.tp('VoucherDetailsPage', 'LIMITED OFFER - BLACK WEEK! {{ festival }}: early booking access with -18% discount', {
    festival: `RestaurantWeek® ${gt.tp('Seasons', 'Spring')}`,
  }),
  question: gt.tp('VoucherDetailsPage', 'How does the gift reservation work?'),
  description1: gt.tp(
    'VoucherDetailsPage',
    'Book your dream restaurant experience for a selected number of guests on a specific date. You will receive a beautiful printable booking confirmation.'
  ),
  description2: gt.tp(
    'VoucherDetailsPage',
    `The code on the confirmation will be used to pay for the reservation of a table at the restaurant of your dreams on the date of your choice.`
  ),
  faq: getVoucherDetailsFaq(gt, `RestaurantWeek® ${gt.tp('Seasons', 'Spring')}`, '2025-04-16'),
  summary: getVoucherDetailsFaqSummary(gt),
})
