import { GetFestivalCopy } from '../..'
import { getVoucherDetailsFaq, getVoucherDetailsFaqSummary, VoucherDetailsCopy } from '..'

export const getRwCopy: GetFestivalCopy<VoucherDetailsCopy> = gt => ({
  title: gt.tp('VoucherDetailsPage', 'Reservation for a gift 🎁'),
  question: gt.tp('VoucherDetailsPage', 'How does the gift reservation work?'),
  description1: gt.tp(
    'VoucherDetailsPage',
    `Book the restaurant experience of your dreams for yourself or a loved one. Just select the number of Guests and you will receive a beautiful printable confirmation.`
  ),
  description2: gt.tp(
    'VoucherDetailsPage',
    `The code on the confirmation will be used to pay for the reservation of a table at the restaurant of your dreams on the date of your choice.`
  ),
  faq: getVoucherDetailsFaq(gt, `RestaurantWeek® ${gt.tp('Seasons', 'Spring')}`, '2025-04-16'),
  summary: getVoucherDetailsFaqSummary(gt),
})
