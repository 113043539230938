import dayjs from 'dayjs'

import { gt as GT } from '~/locale'
import { FestivalSalesOffer } from '~/types/festival'

import { GetVoucherCopy } from '..'

import { getBlackFridayCopy } from './BLACK_FRIDAY'
import { getGiftCopy } from './GIFT'
import { getWRCopy } from './WR'

export type VoucherDetailsCopy = {
  title: string
  question: string
  description1: string
  description2: string
  faq: {
    title: string
    description: string
  }[]
  summary: {
    title: string
    content: {
      description: string
    }
    btnLabel: string
  }
}

// /voucher-details page
export const getVoucherDetailsCopy: GetVoucherCopy<VoucherDetailsCopy> = festivalSalesOffer => {
  switch (festivalSalesOffer) {
    case FestivalSalesOffer.BLACK_FRIDAY:
      return getBlackFridayCopy
    case FestivalSalesOffer.WR:
      return getWRCopy
    case FestivalSalesOffer.GIFT:
    default:
      return getGiftCopy
  }
}

const formatDate = (date: string) => dayjs.parseZone(date).format('D MMMM YYYY')

export const getVoucherDetailsFaq = (gt: typeof GT, festival: string, date: string) => [
  {
    title: gt.tp('VoucherDetailsPage', 'When and in what form will I receive my reservation code?'),
    description: gt.tp(
      'VoucherDetailsPage',
      'Immediately after purchasing your invitation, you will receive a beautiful gift PDF with a code for {{ festival }} edition.',
      { festival: `${festival} 2025` }
    ),
  },
  {
    title: gt.tp('VoucherDetailsPage', 'How to use the code?'),
    description: gt.tp(
      'VoucherDetailsPage',
      'Use the code in the payment window at the end of the booking. Paste the code in the last booking step after expanding the Add Discount Code field. The value of the code reduces the final amount to be paid. The code can be used for a larger reservation by paying the difference.'
    ),
  },
  {
    title: gt.tp('VoucherDetailsPage', 'Until when is the code valid?'),
    description: gt.tp(
      'VoucherDetailsPage',
      'The code can only be used during the {{ festival }} Festival. The code is unique and one-time use and expires on {{ date }}. Only one code can be used per reservation. The code cannot be combined with other promotions/codes. Codes are not refundable in any form or convertible into money. Terms and conditions for refunds are set forth in the terms and conditions of Advance Reservations.',
      { festival: `${festival} 2025`, date: formatDate(date) }
    ),
  },
]

export const getVoucherDetailsFaqSummary = (gt: typeof GT) => ({
  title: gt.tp('VoucherDetailsPage', 'Guests'),
  content: {
    description: gt.tp('VoucherDetailsPage', 'Number of guests at table'),
  },
  btnLabel: gt.tp('VoucherDetailsPage', 'Continue'),
})
