import { FestivalCodesEnum } from '~/generated/graphql'

import { GeFestivalSalesOfferCopy } from '../..'
import { VoucherDetailsCopy } from '..'

import { getFdwCopy } from './Fdw'
import { getRwCopy } from './Rw'

export const getBlackFridayCopy: GeFestivalSalesOfferCopy<VoucherDetailsCopy> = code => {
  switch (code) {
    case FestivalCodesEnum.Fdw:
      return getFdwCopy
    case FestivalCodesEnum.Rw:
    default:
      return getRwCopy
  }
}
