// @ts-strict-ignore
import { useEffect } from 'react'

import Image from 'next/image'
import { useRouter } from 'next/router'

import { useModal } from '~/shared/molecules/Modal'
import { SignInCallback } from '~/types/signIn'

import RegisterModalView from '../RegisterModal/RegisterModalView'

import { LoginForm } from './LoginForm'
import { StyledFormWrapper, StyledImageWrapper, StyledLoginModal } from './LoginModalShards'
import { bigModalProps } from './LoginModalUtils'

interface LoginModalViewProps {
  handleClose: () => void
  onLogin?: SignInCallback
}

const LoginModalView = ({ handleClose, onLogin }: LoginModalViewProps) => {
  return (
    <StyledLoginModal>
      <StyledFormWrapper>
        <LoginForm handleClose={handleClose} onLogin={onLogin} />
      </StyledFormWrapper>
      <StyledImageWrapper>
        <Image src='/assets/login/loginFormPhoto.jpg' objectFit={'cover'} layout={'fill'} height={750} width={500} />
      </StyledImageWrapper>
    </StyledLoginModal>
  )
}

export const LoginModalWrapper = (Component: any): React.FC<any> => {
  const InnerComponent = props => {
    const { query, ...router } = useRouter()
    const modal = useModal()
    const showLogin = query?.showLogin
    const showRegister = query?.showRegister

    useEffect(() => {
      const handleClose = () => {
        modal.close()
        router.replace('/', undefined, { shallow: true })
      }

      if (showLogin) {
        modal.open(
          {
            ...bigModalProps,
            handleClose,
          },
          <LoginModalView handleClose={handleClose} {...props} />
        )
      }

      if (showRegister) {
        modal.open(
          {
            ...bigModalProps,
            handleClose,
          },
          <RegisterModalView handleClose={handleClose} {...props} />
        )
      }
    }, [query?.showLogin, query?.showRegister])

    return <Component {...props} />
  }

  return InnerComponent
}

export default LoginModalView
