import { Formik } from 'formik'

import { ApolloError } from '@apollo/client'

import { Heading2, Heading5 } from '~/theme/utils/typography'
import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { CreateUserMutationVariables, useCreateUserMutation, UserDataDocument, useUserDataQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Checkbox from '~/shared/atoms/Checkbox'
import Input from '~/shared/atoms/Input'
import { useToast } from '~/shared/atoms/Toast'
import { bigModalPropsTemplate, smallModalPropsTemplate, useModal } from '~/shared/molecules/Modal'
import { SignInCallback } from '~/types/signIn'

import { AppleLoginButton } from '../AppleLogin/AppleLoginButton'
import FacebookLoginButton from '../FacebookLoginButton'
import GoogleLoginButton from '../GoogleLoginButton'
import LoginModal from '../LoginModal'
import { OtherLogin, OtherLoginButtons, StyledToastContainer } from '../LoginModal/LoginModalShards'
import VerifyAccountModal from '../VerifyAccountModal'

import { StyledCheckboxWrapper, StyledForm, StyledInputs, StyledLink } from './RegisterModalShards'
import { REGISTER_FORM_FIELDS, registerSchema } from './RegisterModalUtils'

type RegisterFormProps = {
  prefilledData?: Partial<CreateUserMutationVariables>
  mainHeading?: string
  onRegister?: SignInCallback
  handleClose?: () => void
}

export const RegisterForm = ({ handleClose, mainHeading, onRegister, prefilledData }: RegisterFormProps) => {
  const { refetch } = useUserDataQuery()
  const [createUser] = useCreateUserMutation()
  const modal = useModal()
  const toast = useToast()

  const handleErrors = (error: ApolloError) => {
    const errors = formatErrorMessages(error)
    errors?.map(error =>
      toast.add({
        message: error,
        type: 'error',
        containerSelector: '#RegisterToastContainer',
        size: 'small',
      })
    )
  }

  const onSocialSignUp: SignInCallback = async user => {
    await refetch()
    if (user) onRegister?.(user)
    handleClose && handleClose()
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        marketingConsentAccepted: false,
        ...prefilledData,
      }}
      validationSchema={registerSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await createUser({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            phoneNumber: values.phoneNumber,
            marketingConsentAccepted: values.marketingConsentAccepted,
          },
          onCompleted: data => {
            modal?.open(smallModalPropsTemplate, <VerifyAccountModal handleClose={modal.close} />)
            onRegister?.(data?.createUser.user ?? null)
          },
          refetchQueries: [{ query: UserDataDocument }, 'UserData'],
          onError: error => handleErrors(error),
        })
        setSubmitting(false)
      }}>
      {({ errors, touched, values, handleSubmit, isSubmitting, isValid, handleChange, handleBlur }) => (
        <StyledForm>
          <Heading2 fontWeight='medium'>{mainHeading}</Heading2>
          <Heading5 fontWeight='medium'>
            {gt.tp('RegisterForm', 'Already have an account?')}{' '}
            <StyledLink onClick={() => modal?.open(bigModalPropsTemplate, <LoginModal handleClose={modal.close} />)}>
              {gt.tp('RegisterForm', 'Log in here')}
            </StyledLink>
          </Heading5>
          <StyledToastContainer id={'RegisterToastContainer'} />
          <StyledInputs>
            {REGISTER_FORM_FIELDS.map(field => (
              <Input
                key={field.name}
                title={gt.tp('RegisterForm', field.title)}
                name={field.name}
                type={field.type}
                description={gt.tp('RegisterForm', field.description)}
                actionsRight={[]}
                error={touched[field.name as keyof typeof touched] && !!errors[field.name as keyof typeof errors]}
                errorMessage={
                  touched[field.name as keyof typeof touched] ? gt.tp('Error', errors[field.name as keyof typeof errors] as string) : ''
                }
                height={'big'}
                width={'full'}
                onChange={handleChange}
                onBlur={handleBlur}
                // @ts-ignore
                value={values[field.name as keyof typeof values]}
              />
            ))}
          </StyledInputs>
          <StyledCheckboxWrapper>
            <Checkbox
              name='marketingConsentAccepted'
              onChange={handleChange}
              onBlur={handleBlur}
              checked={!!values.marketingConsentAccepted}
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${gt.tp(
                      'RegisterForm',
                      '{{ thankYouBold }} - I want to use DISCOUNT CODES, receive notifications about Festivals, and get the "RESTAURANT RECIPES" eBook {{ valuedAtBold }}. Therefore, I consent to receiving commercial information',
                      {
                        thankYouBold: `<b>${gt.tp('RegisterForm', 'Yes, thank you')}</b>`,
                        valuedAtBold: `<b>${gt.tp('RegisterForm', '(valued at PLN 78)')}</b>`,
                      }
                    )}`,
                  }}></span>
              }
              showMore={gt.tp(
                'RegisterForm',
                'from Restaurant Club sp. z o.o. to the contact details I provided and to the use of these details for direct marketing purposes via electronic communication devices (email, social media platforms, SMS to the provided phone number, and phone contact).'
              )}
            />
          </StyledCheckboxWrapper>
          <Button
            label={gt.tp('RegisterForm', 'Register')}
            height='big'
            width='full'
            color='green'
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            onClick={() => handleSubmit()}
          />
          <OtherLogin>{gt.tp('LoginForm', 'or')}</OtherLogin>
          <OtherLoginButtons>
            <GoogleLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Google')} />
            <FacebookLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Facebook')} />
            <AppleLoginButton onLogin={onSocialSignUp} label={gt.tp('Register', 'Sign up with Apple')} />
          </OtherLoginButtons>
        </StyledForm>
      )}
    </Formik>
  )
}
