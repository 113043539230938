import { paths } from '~/constants/paths'
import { gt } from '~/locale'

export const USER_MENU_ROUTES = [
  {
    label: gt.tp('UserMenuItem', 'Reservations'),
    icon: 'menu',
    href: paths.reservations,
  },
  {
    label: gt.tp('UserMenuItem', 'Favorite restaurants'),
    icon: 'heart',
    href: paths.favorites,
  },
  {
    label: gt.tp('UserMenuItem', 'Your account'),
    icon: 'person',
    href: paths.userInformation,
  },
  // {
  //   label: gt.tp('UserMenuItem', 'Payment methods'),
  //   icon: 'card',
  //   href: paths.paymentOptions,
  // },
  {
    label: gt.tp('UserMenuItem', 'Settings and consents'),
    icon: 'settings',
    href: paths.settings,
  },
]
