import { Formik } from 'formik'

import { ApolloError } from '@apollo/client'

import { Heading2, Heading5 } from '~/theme/utils/typography'
import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { UserDataDocument, useSignInMutation, useUserDataQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Input from '~/shared/atoms/Input'
import Link from '~/shared/atoms/Link'
import { useToast } from '~/shared/atoms/Toast'
import { bigModalPropsTemplate, smallModalPropsTemplate, useModal } from '~/shared/molecules/Modal'
import { SignInCallback } from '~/types/signIn'

import { AppleLoginButton } from '../AppleLogin/AppleLoginButton'
import FacebookLoginButton from '../FacebookLoginButton'
import ForgotPasswordModal from '../ForgotPasswordModal'
import GoogleLoginButton from '../GoogleLoginButton'
import RegisterModal from '../RegisterModal'

import { OtherLogin, OtherLoginButtons, StyledForm, StyledFormLinkContainer, StyledLink, StyledToastContainer } from './LoginModalShards'
import { LOGIN_FORM_FIELDS, loginSchema } from './LoginModalUtils'

type LoginFormProps = {
  onLogin?: SignInCallback
  onRegisterLinkClick?: () => void
  handleClose: () => void
}

export const LoginForm = ({ onLogin, onRegisterLinkClick, handleClose }: LoginFormProps) => {
  const { refetch } = useUserDataQuery()
  const [signIn] = useSignInMutation({
    refetchQueries: [{ query: UserDataDocument }, 'UserData'],
    onCompleted: data => {
      onLogin && onLogin(data.signIn?.user ?? null)
    },
  })
  const modal = useModal()
  const toast = useToast()

  const onRegisterClick =
    onRegisterLinkClick ||
    (() => {
      modal?.open(bigModalPropsTemplate, <RegisterModal handleClose={modal.close} />)
    })

  const handleErrors = (error: ApolloError) => {
    const errors = formatErrorMessages(error)
    errors?.map(error =>
      toast.add({
        message: error,
        type: 'error',
        containerSelector: '#LoginToastContainer',
        size: 'small',
      })
    )
  }

  const handleSocialLogin: SignInCallback = data => {
    if (!data) return

    onLogin && onLogin(data)
    refetch()
    handleClose()
  }
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={loginSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await signIn({
          variables: {
            email: values.email,
            password: values.password,
          },
          onCompleted: handleClose,
          onError: error => handleErrors(error),
        })
        setSubmitting(false)
      }}>
      {({ errors, touched, values, handleSubmit, isSubmitting, isValid, handleChange, handleBlur }) => (
        <StyledForm>
          <Heading2 fontWeight='medium'>{gt.tp('LoginForm', 'Log in')}</Heading2>
          <Heading5 fontWeight='medium'>
            {gt.tp('LoginForm', 'Not registered yet?')}{' '}
            <StyledLink onClick={onRegisterClick}>{gt.tp('LoginForm', 'Create an account')}</StyledLink>
          </Heading5>
          <StyledToastContainer id={'LoginToastContainer'} />
          {LOGIN_FORM_FIELDS.map(field => (
            <Input
              key={field.title}
              title={gt.tp('LoginForm', field.title || '')}
              name={field.name}
              type={field.type}
              description=''
              actionsRight={[]}
              error={touched[field.name as keyof typeof touched] && !!errors[field.name as keyof typeof errors]}
              errorMessage={
                touched[field.name as keyof typeof touched] ? gt.tp('Error', errors[field.name as keyof typeof errors] || '') : ''
              }
              height={'big'}
              width={'full'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[field.name as keyof typeof values]}
            />
          ))}
          <StyledFormLinkContainer onClick={() => modal?.open(smallModalPropsTemplate, <ForgotPasswordModal />)}>
            <Link size='big' label={gt.tp('LoginForm', 'Forgot password?')} />
          </StyledFormLinkContainer>
          <Button
            label={gt.tp('LoginForm', 'Log in')}
            height='big'
            width='full'
            color='green'
            type='submit'
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            onClick={() => handleSubmit()}
          />
          <OtherLogin>{gt.tp('LoginForm', 'or')}</OtherLogin>
          <OtherLoginButtons>
            <GoogleLoginButton onLogin={handleSocialLogin} />
            <FacebookLoginButton onLogin={handleSocialLogin} />
            <AppleLoginButton onLogin={handleSocialLogin} />
          </OtherLoginButtons>
        </StyledForm>
      )}
    </Formik>
  )
}
