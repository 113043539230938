import { FestivalSalesOffer } from '~/types/festival'

import { VoucherImagesFunction } from '.'

export enum EventsListingRoutes {
  GiftEventsListing = 'gift-events-listing',
  SideEventsListing = 'side-events-listing',
}

type BannerBreakpoint = {
  desktop: string
  mobile: string
}

type GiftEventsListingBanners = {
  [EventsListingRoutes.GiftEventsListing]: {
    mainBanner: BannerBreakpoint
    bottomBanner?: BannerBreakpoint
  }
  [EventsListingRoutes.SideEventsListing]: {
    mainBanner: BannerBreakpoint
    bottomBanner?: BannerBreakpoint
  }
}

const SideEventsListing = {
  mainBanner: {
    desktop: 'side-events-listing/banner.png',
    mobile: 'side-events-listing/banner-mobile.png',
  },
}

// /gift-events-listing
export const getVoucherEventsListingBanners: VoucherImagesFunction<GiftEventsListingBanners> = festivalSalesOffer => {
  switch (festivalSalesOffer) {
    case FestivalSalesOffer.WR:
      return {
        [EventsListingRoutes.GiftEventsListing]: {
          mainBanner: {
            desktop: 'gift-events-listing/WR_banner_desktop-cz.png',
            mobile: 'gift-events-listing/WR_banner_mobile-cz.png',
          },
        },
        [EventsListingRoutes.SideEventsListing]: SideEventsListing,
      }
    case FestivalSalesOffer.BLACK_FRIDAY:
      return {
        [EventsListingRoutes.GiftEventsListing]: {
          mainBanner: {
            desktop: 'gift-events-listing/BW24-banner-desktop.png',
            mobile: 'gift-events-listing/BW24-banner-mobile.png',
          },
          bottomBanner: {
            desktop: 'gift-events-listing/BF24-banner-porownawczy-desktop.png',
            mobile: 'gift-events-listing/BF24-banner-porownawczy-mobile.png',
          },
        },
        [EventsListingRoutes.SideEventsListing]: SideEventsListing,
      }
    case FestivalSalesOffer.XMAS:
      return {
        [EventsListingRoutes.GiftEventsListing]: {
          mainBanner: {
            desktop: 'gift-events-listing/XMAS24-banner-desktop.png',
            mobile: 'gift-events-listing/XMAS24-banner-mobile.png',
          },
          bottomBanner: {
            desktop: 'gift-events-listing/XMAS24-banner-porownawczy-desktop.png',
            mobile: 'gift-events-listing/XMAS24-banner-porownawczy-mobile.png',
          },
        },
        [EventsListingRoutes.SideEventsListing]: SideEventsListing,
      }
    case FestivalSalesOffer.GIFT:
    default:
      return {
        [EventsListingRoutes.GiftEventsListing]: {
          mainBanner: {
            desktop: 'gift-events-listing/banner.png',
            mobile: 'gift-events-listing/banner-mobile.png',
          },
        },
        [EventsListingRoutes.SideEventsListing]: SideEventsListing,
      }
  }
}
