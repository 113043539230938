import React, { useEffect, useState } from 'react'
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login'
import FacebookLogin, { RenderProps } from 'react-facebook-login/dist/facebook-login-render-props'

import { get } from 'lodash'

import config from '~/config/index'
import { useFacebookSignInMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import { SignInCallback } from '~/types/signIn'

import LoginButton from '../LoginButton/LoginButtonView'

type FacebookLoginButtonProps = {
  onLogin?: SignInCallback
  label?: string
}

const FacebookLoginButtonView: React.FC<FacebookLoginButtonProps> = ({ label, onLogin }) => {
  const { tp } = gt
  const [isSdkReady, setSdkReady] = useState<boolean>(false)
  const [facebookSignIn] = useFacebookSignInMutation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.FB) {
        setSdkReady(true)
        return
      }

      window.fbAsyncInit = function () {
        window.FB.init({
          appId: config().facebookAppId,
          cookie: true,
          xfbml: true,
          version: 'v18.0',
        })

        setSdkReady(true)
      }
      ;(function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        // eslint-disable-next-line prefer-const
        const js = d.createElement(s) as HTMLScriptElement
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode?.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }
  }, [])

  const responseFacebook = (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    const accessToken = get(response, 'accessToken')
    facebookSignIn({
      variables: {
        accessToken,
      },
    })
      .then(result => {
        onLogin && onLogin(result.data?.facebookSignIn.user || null)
      })
      .catch(e => {})
  }

  return isSdkReady ? (
    <FacebookLogin
      appId={config().facebookAppId}
      autoLoad
      callback={responseFacebook}
      render={(renderProps: RenderProps) => (
        <LoginButton icon='facebook' onClick={renderProps.onClick} label={label ?? tp('Login', 'Sign with Facebook')} />
      )}
    />
  ) : null
}

export default FacebookLoginButtonView
