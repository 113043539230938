import yup from '~/utils/validationMessages'

import { errors } from '~/constants/errors'

export const validationFields = {
  email: yup.string().email().required(),
  firstName: yup.string().max(100).required(),
  lastName: yup.string().max(100).required(),
  password: yup.string().required(),
  phoneNumber: yup
    .string()
    .matches(/^\+?\d*$/, errors.validation.mixed.integers_only)
    .min(9)
    .max(13),
}
