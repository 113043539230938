import { GetFestivalCopy } from '../..'
import { getVoucherDetailsFaqSummary, VoucherDetailsCopy } from '..'

export const getRwCopy: GetFestivalCopy<VoucherDetailsCopy> = gt => ({
  title: gt.tp('VoucherDetailsPage', 'Reservation for a gift 🎁'),
  question: gt.tp('VoucherDetailsPage', 'How does booking as a gift work?'),
  description1: gt.tp(
    'VoucherDetailsPage',
    'Book your dream restaurant experience for a selected number of guests on a specific date. You will receive a beautiful printable booking confirmation.'
  ),
  description2: gt.tp(
    'VoucherDetailsPage',
    'The code from the confirmation can be redeemed while reserving a table at your dream restaurant on the selected date.'
  ),
  faq: [
    {
      title: gt.tp('VoucherDetailsPage', 'When and in what form will I receive the reservation code?'),
      description: gt.tp(
        'VoucherDetailsPage',
        'Immediately after purchasing the invitation, you will receive an email and you’ll be able to generate a beautiful PDF gift voucher with a code for the spring edition of the Restaurant Week 2025 (to the address specified during purchase).'
      ),
    },
    {
      title: gt.tp('VoucherDetailsPage', 'How to redeem the code?'),
      description: gt.tp(
        'VoucherDetailsPage',
        'You apply the code in the payment window at the end of the reservation. Enter the code in the last step of the reservation after expanding the Add discount code field. After applying the code, the amount to be paid will be reduced by its value (2 or more guests). The code can be applied to a reservation with more guests - paying the difference (eg if you buy a code for 2 guests and want to make a reservation for 3 guests, you will only pay for one guest with the code).'
      ),
    },
    {
      title: gt.tp('VoucherDetailsPage', 'Until when is my code valid?'),
      description: gt.tp(
        'VoucherDetailsPage',
        'The code can only be used during the Restaurant Week Spring 2025 festival. The code is unique and one-time use and is valid until April 19, 2025. Only one code can be used per reservation. The code cannot be combined with other promotions/codes. Codes are non-refundable and cannot be exchanged for money. The conditions for refunds are set out in the terms and conditions for Advance Bookings.'
      ),
    },
  ],
  summary: getVoucherDetailsFaqSummary(gt),
})
