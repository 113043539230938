import { GetFestivalCopy } from '../..'
import { getVoucherDetailsFaq, VoucherDetailsCopy } from '..'

import { getRwCopy } from './Rw'

export const getFdwCopy: GetFestivalCopy<VoucherDetailsCopy> = gt => ({
  ...getRwCopy(gt),
  title: gt.tp('VoucherDetailsPage', 'LIMITED OFFER - BLACK WEEK! {{ festival }}: early booking access with -18% discount', {
    festival: 'FineDiningWeek®',
  }),
  faq: getVoucherDetailsFaq(gt, 'FineDiningWeek®', '2025-08-09'),
})
