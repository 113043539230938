import { GetFestivalCopy } from '../..'
import { VoucherOrderCopy } from '..'

export const getRwCopy: GetFestivalCopy<VoucherOrderCopy> = gt => ({
  title: gt.tp('VoucherOrderPage', 'Payment and Summary'),
  section: {
    title: gt.tp('VoucherOrderPage', '1. Reservation for a gift 🎁'),
    date: gt.tp('VoucherOrderPage', 'To be used by'),
    guestCount: peopleCount => gt.tnp('VoucherOrderPage', '{{ count }} Guest', '{{ count }} Guests', peopleCount, { count: peopleCount }),
  },
  summary: {
    title: gt.tp('VoucherOrderPage', '2. Summary'),
    target: gt.tp('VoucherOrderPage', 'Reservation for a gift'),
    reservationFee: gt.tp('VoucherOrderPage', 'Reservation fee'),
    total: gt.tp('Checkout', 'Total'),
  },
})
