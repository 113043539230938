import { GetFestivalCopy } from '../..'
import { VoucherSuccessScreenCopy } from '..'

export const getRwCopy: GetFestivalCopy<VoucherSuccessScreenCopy> = gt => ({
  succeeded: gt.tp('OrderStatus', 'Your reservation has been confirmed!'),
  congratulations: festival =>
    gt.tp('SuccessPage', 'Congratulations! You have reservation for a gift for {{ festival }}', {
      festival,
    }),
  title: gt.tp('SuccessPage', 'Read before you visit'),
  description: gt.tp(
    'SuccessPage',
    // eslint-disable-next-line max-len
    'Be eco! Don\'t print the confirmation - at the restaurant, the confirmation on your phone will be enough. Please be on time :). Punctuality gives everyone an equal opportunity to enjoy their dream restaurant experience. After the visit, log in to your account, select "Your reservations" and "Review". The restaurant counts on your honest opinion!'
  ),
})
