// @ts-strict-ignore
import Image from 'next/image'

import { Heading3, Heading5 } from '~/theme/utils/typography'

import { CreateUserMutationVariables } from '~/generated/graphql'
import { gt } from '~/locale'
import { SignInCallback } from '~/types/signIn'

import { StyledFormWrapper, StyledImageWrapper } from '../LoginModal/LoginModalShards'

import { RegisterForm } from './RegisterForm'
import { StyledCheckIcon, StyledTextWrapper, Wrapper } from './RegisterModalShards'

interface Props {
  prefilledData?: Partial<CreateUserMutationVariables>
  hasImage?: boolean
  mainHeading?: string
  onRegister?: SignInCallback
  handleClose?: () => void
}

const RegisterModalView: React.FC<Props> = ({ prefilledData, hasImage = true, mainHeading, handleClose, onRegister }) => {
  return (
    <Wrapper hasImage={hasImage}>
      <StyledFormWrapper>
        <RegisterForm handleClose={handleClose} mainHeading={mainHeading} prefilledData={prefilledData} onRegister={onRegister} />
      </StyledFormWrapper>
      {hasImage && (
        <StyledImageWrapper>
          <Image
            layout='fill'
            objectFit='cover'
            height={750}
            objectPosition='right'
            width={500}
            src='/assets/login/registerFormPhoto.jpg'
          />
          <StyledTextWrapper>
            <Heading3 fontWeight='medium'>{gt.tp('RegisterForm', 'Register your Guest Account today!')}</Heading3>
            <Heading5 fontWeight='medium'>
              <StyledCheckIcon icon='check-bold' />
              {gt.tp('RegisterForm', 'Invite your loved ones!')}
            </Heading5>
            <Heading5 fontWeight='medium'>
              <StyledCheckIcon icon='check-bold' />
              {gt.tp('RegisterForm', 'Book your favorite menu. Discover new restaurants.')}
            </Heading5>
            <Heading5 fontWeight='medium'>
              <StyledCheckIcon icon='check-bold' />
              {gt.tp('RegisterForm', 'Thousands of signature dishes, hundreds of best restaurants awaits for you!')}
            </Heading5>
            <Heading5 fontWeight='medium'>
              <StyledCheckIcon icon='check-bold' />
              {gt.tp('RegisterForm', 'Benefit from discounts and offers thanks to RestaurantClub friends.')}
            </Heading5>
          </StyledTextWrapper>
        </StyledImageWrapper>
      )}
    </Wrapper>
  )
}

RegisterModalView.defaultProps = {
  prefilledData: undefined,
  hasImage: true,
  mainHeading: gt.tp('RegisterForm', 'Register'),
}

export default RegisterModalView
