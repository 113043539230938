import React from 'react'

import { useGoogleLogin } from '@react-oauth/google'

import { useGoogleSignInMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import { SignInCallback } from '~/types/signIn'

import LoginButton from '../LoginButton'

type GoogleLoginButtonProps = {
  onLogin?: SignInCallback
  label?: string
}

const GoogleLoginButtonView: React.FC<GoogleLoginButtonProps> = ({ label, onLogin }) => {
  const [googleSignIn] = useGoogleSignInMutation()
  const { tp } = gt

  const login = useGoogleLogin({
    onSuccess: response => {
      const accessToken = response.access_token
      googleSignIn({
        variables: {
          accessToken,
        },
      })
        .then(result => {
          onLogin && onLogin(result.data?.googleSignIn.user || null)
        })
        .catch(e => {})
    },
    flow: 'implicit',
  })

  return <LoginButton icon='google' label={label ?? tp('Login', 'Sign with Google')} onClick={() => login()} />
}

export default GoogleLoginButtonView
