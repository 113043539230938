// @ts-strict-ignore
import styled from '@emotion/styled'

export const breakpoints = {
  s: '375px',
  m: '600px',
  l: '1205px',
  xl: '1440px',
}

export const mediaQueryIsTablet = `(max-width: ${breakpoints.l})`

export const mediaQueryUp = bp => `@media (min-width: ${breakpoints[bp]})`
export const mediaQueryUpVal = val => `(min-width: ${val}px)`
export const mediaQueryDownVal = val => `(max-width: ${val}px)`
export const mediaQueryDown = bp => `@media (max-width: ${breakpoints[bp]})`

export const mediaQueryIsMobile = `(max-width: ${breakpoints.m})`

export const pagePaddings = {
  s: 1,
  m: 2.87,
  l: 3.75,
  xl: 5,
  xxl: 20,
}

export const UniversalContainer = styled.div<{ extraPaddingOnMobile?: boolean }>`
  width: 100%;
  padding-left: ${pagePaddings.s}rem;
  padding-right: ${pagePaddings.s}rem;
  max-width: 1440px;
  margin: 0 auto;

  ${mediaQueryUp('m')} {
    padding-left: ${pagePaddings.m}rem;
    padding-right: ${pagePaddings.m}rem;
  }

  ${mediaQueryUp('l')} {
    padding-left: ${pagePaddings.l}rem;
    padding-right: ${pagePaddings.l}rem;
  }

  ${mediaQueryUp('xl')} {
    padding-left: ${pagePaddings.xl}rem;
    padding-right: ${pagePaddings.xl}rem;
  }
`

export const UniversalSmallContainer = styled.div<{ extraPaddingOnMobile?: boolean }>`
  width: 100%;
  padding-left: ${pagePaddings.s}rem;
  padding-right: ${pagePaddings.s}rem;
  max-width: 1440px;
  margin: 0 auto;

  ${mediaQueryUp('m')} {
    padding-left: ${pagePaddings.xl}rem;
    padding-right: ${pagePaddings.xl}rem;
  }

  ${mediaQueryUp('l')} {
    padding-left: ${pagePaddings.xxl}rem;
    padding-right: ${pagePaddings.xxl}rem;
  }

  ${mediaQueryUp('xl')} {
    padding-left: ${pagePaddings.xxl}rem;
    padding-right: ${pagePaddings.xxl}rem;
  }
`

export const Container = styled.div<{ paddingTop?: number }>`
  width: 100%;
  padding: ${({ paddingTop = 0 }) => `${paddingTop}px 16px 0`};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;

  ${mediaQueryUp('s')} {
    padding: ${({ paddingTop }) => `${paddingTop}px 20px 0`};
  }

  ${mediaQueryUp('m')} {
    padding: ${({ paddingTop }) => `${paddingTop}px 46px 0`};
    grid-column-gap: 16px;
  }

  ${mediaQueryUp('l')} {
    padding: ${({ paddingTop }) => `${paddingTop}px 60px 0`};
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 24px;
  }

  ${mediaQueryUp('xl')} {
    width: 1200px;
    margin: 0 auto;
    padding: ${({ paddingTop }) => `${paddingTop}px 120px 0`};
    grid-template-columns: repeat(12, 1fr);
  }
`
