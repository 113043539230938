import { GetFestivalCopy } from '../..'
import { VoucherSuccessScreenCopy } from '..'

export const getRwCopy: GetFestivalCopy<VoucherSuccessScreenCopy> = gt => ({
  succeeded: gt.tp('OrderStatus', 'Booking code reservation confirmed!'),
  congratulations: festival =>
    gt.tp('SuccessPage', 'Congratulations! You have a reservation code for reservation as a gift for {{ festival }}', {
      festival,
    }),
  title: gt.tp('SuccessPage', 'Important'),
  description: gt.tp(
    'SuccessPage',
    'Congratulations! :) We have sent a code to your email address. Just copy the purchased code and apply it to the reservation in the restaurant of your choice.'
  ),
})
