// @ts-strict-ignore
import { validationFields } from '~/utils/validationFields'
import yup from '~/utils/validationMessages'

import { errors } from '~/constants/errors'
import { gt } from '~/locale'

export const REGISTER_FORM_FIELDS = [
  {
    description: '',
    title: 'First name*',
    name: 'firstName',
    type: 'text',
  },
  {
    description: '',
    title: 'Last name*',
    name: 'lastName',
    type: 'text',
  },
  {
    description: '',
    title: 'Email*',
    name: 'email',
    type: 'email',
  },
  {
    description: '',
    title: 'Phone',
    placeholder: '+48 690 729 222',
    name: 'phoneNumber',
    type: 'tel',
  },
  {
    description: 'Password must be at least 8 characters long, with one uppercase and one lowercase letter at the minimum',
    title: 'Password*',
    name: 'password',
    type: 'password',
  },
]

export const registerSchema = yup.object().shape({
  firstName: validationFields.firstName,
  lastName: validationFields.lastName,
  phoneNumber: validationFields.phoneNumber,
  email: validationFields.email,
  password: yup
    .string()
    .test({
      name: 'no_spaces',
      message: gt.tp('Error', errors.validation.mixed.no_spaces),
      test: value => value && !value.match(/\s/g),
    })
    .matches(/^(?=.*[a-z])(?=.*[A-Z]).*$/, errors.validation.mixed.mixed_case)
    .min(8)
    .required(),
})
